import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  InputGroup,
  FormControl,
  Button,
  FormLabel,
  Form,
  Row,
  Col,
  Accordion,
  Spinner,
} from "react-bootstrap";
import Menu from "../../Components/Menu";
import { useAuthState } from "../../Context";
import styles from "./bookings.module.css";
import BookingsTable from "../../Components/BookingsTable";
import LoadingSpinner from "../../Components/LoadingSpinner";
import EmailTicketsModal from "../../Components/EmailTicketsModal";
import BookingModal from "../../Components/BookingModal";
import CancelModal from "../../Components/CancelModal";
import RefundModal from "../../Components/RefundModal";
import ModificationModal from "../../Components/ModificationModal";
import useDebounce from "../../hooks/useDebounce";
import WhatsAppTicketsModal from "../../Components/WhatsAppTicketsModal";
import ReactExport from "react-export-excel";
import { format } from "date-fns";
import useSearchParams from "../../hooks/useSearchParams";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PER_PAGE = 50;

function Bookings() {
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState("createdAt");
  const debouncedSorting = useDebounce(sorting, 500)
  const [sortWay, setSortWay] = useState("DESC");
  const debouncedSortWay = useDebounce(sortWay, 500)
  const userDetails = useAuthState();
  const [bookingIndex, setBookingIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showWhatsAppTicketsModal, setShowWhatsAppTicketsModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showModificationModal, setShowModificationModal] = useState(false)

  const [bookingsToExport, setBookingsToExport] = useState([])
  const [excelStatus, setExcelStatus] = useState("idle")

  const { pushSearchParam, getParam } = useSearchParams()

  const trayecto = getParam("trayecto")
  const status = getParam("status")
  const fecha = getParam("fecha")
  const fechaRegreso = getParam("fechaRegreso")
  const createdAt = getParam("createdAt")
  const vendedor = getParam("vendedor")
  const localizador = getParam("localizador")
  const name = getParam("name")
  const mail = getParam("mail")

  useEffect(() => { if (!status) pushSearchParam({ target: { value: "confirmed", name: "status" } }) }, [])

  const debouncedLocalizador = useDebounce(localizador, 1000)
  const debouncedName = useDebounce(name, 1000)
  const debouncedMail = useDebounce(mail, 1000)
  const debouncedFecha = useDebounce(fecha, 1000)
  const debouncedCreatedAt = useDebounce(createdAt, 1000)
  const debouncedFechaRegreso = useDebounce(fechaRegreso, 1000)


  const fecthBookings = async ({ signal }) => {
    const url = `${process.env.REACT_APP_BACKEND_URL
      }/${userDetails.user.role.type === "hotel" ? "mybookings" : "bookings"}?_sort=${debouncedSorting}:${debouncedSortWay}&_start=${currentPage * PER_PAGE
      }&_limit=${PER_PAGE + 1}${debouncedLocalizador ? `&localizador_contains=${debouncedLocalizador}` : ""
      }${debouncedName ? `&firstName_contains=${debouncedName}` : ""}${debouncedMail ? `&email_contains=${debouncedMail}` : ""
      }${debouncedCreatedAt ? `&createdAt_gte=${debouncedCreatedAt}T00:00:00.000Z&createdAt_lte=${debouncedCreatedAt}T23:59:59.999` : ""}${debouncedFecha ? `&fecha_ida=${debouncedFecha}` : ""}${debouncedFechaRegreso ? `&fecha_vuelta=${debouncedFechaRegreso}` : ""}${status ? `&status=${status}` : ""
      }${trayecto ? `&trayecto_ida_contains=${trayecto}` : ""}${vendedor ? `&user=${vendedor}` : ""}`;
    console.log("fetch bkngs", url)
    const { data } = await axios.get(url, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("BOOKINGS", data)
    return data
  }

  const bookings = useQuery(['bookings', debouncedMail, debouncedLocalizador, debouncedName, currentPage, debouncedCreatedAt, debouncedFecha, debouncedFechaRegreso, status, trayecto, vendedor, debouncedSorting, debouncedSortWay], fecthBookings)

  const users = useQuery('users', async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    return data
  }, { enabled: userDetails.user.role.type !== "hotel" })


  useEffect(() => {
    setCurrentPage(0)
  }, [mail, localizador, name, debouncedCreatedAt, debouncedFecha, debouncedFechaRegreso, status, trayecto])


  const toggleSortWay = () => {
    setSortWay(value => value === "DESC" ? "ASC" : "DESC")
  }

  const sortBy = (newSorting) => {
    if (sorting === newSorting) toggleSortWay()
    else setSorting(newSorting)
  }


  const fetchBookingsToExport = async () => {
    const batch = 1000
    let page = 0
    setExcelStatus("loading")
    let data = await recursiveFetch(getUrl(batch, page))
    setBookingsToExport(data)
    while (data.length === batch) {
      page = page + 1
      data = await recursiveFetch(getUrl(batch, page))
      setBookingsToExport(list => ([...list, ...data]))
    }
    setExcelStatus("success")
  };
  const getUrl = (batch, page) => {
    return `${process.env.REACT_APP_BACKEND_URL}/bookings?_sort=${debouncedSorting}:${debouncedSortWay}&_limit=${batch}&_start=${page * batch + 1}${debouncedLocalizador ? `&localizador_contains=${debouncedLocalizador}` : ""
      }${debouncedName ? `&firstName_contains=${debouncedName}` : ""}${debouncedMail ? `&email_contains=${debouncedMail}` : ""
      }${debouncedCreatedAt ? `&createdAt_gte=${debouncedCreatedAt}T00:00:00.000Z&createdAt_lte=${debouncedCreatedAt}T23:59:59.999` : ""}${debouncedFecha ? `&ida.date=${debouncedFecha}` : ""}${debouncedFechaRegreso ? `&fecha_vuelta=${debouncedFechaRegreso}` : ""}${status ? `&status=${status}` : ""
      }${trayecto ? `&trayecto_ida_contains=${trayecto}` : ""}${vendedor ? `&user=${vendedor}` : ""}`;
  }

  const recursiveFetch = async (url) => {
    console.log("recursive fetch", url)
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("data", data)
    return data
  }

  const doHideModal = () => {
    setBookingIndex(0)
    setShowModal(false)
  }
  const doShowModal = (index) => {
    setBookingIndex(index)
    setShowModal(true)
  }

  const doShowMailModal = () => {
    setShowEmailModal(true)
  }

  const doShowWhatsAppTicketsModal = () => {
    setShowWhatsAppTicketsModal(true)
  }

  const doShowCancelModal = () => {
    setShowCancelModal(true)
  }

  const doHideCancelModal = () => {
    setShowCancelModal(false)
    bookings.refetch()
  }

  const doShowRefundModal = () => {
    setShowRefundModal(true)
  }

  const doHideRefundModal = () => {
    setShowRefundModal(false)
    bookings.refetch()
  }

  const doShowModificationModal = () => {
    setShowModificationModal(true)
  }

  const doHideModificationModal = () => {
    setShowModificationModal(false)
    bookings.refetch()
  }

  return (
    <>
      {bookings.isSuccess &&
        <>
          <BookingModal
            booking={bookings.data[bookingIndex]}
            showModal={showModal}
            doHideModal={doHideModal}
            doShowMailModal={doShowMailModal}
            doShowCancelModal={doShowCancelModal}
            doShowRefundModal={doShowRefundModal}
            doShowModificationModal={doShowModificationModal}
            doShowWhatsAppTicketsModal={doShowWhatsAppTicketsModal} />
          <EmailTicketsModal
            booking={bookings.data[bookingIndex]}
            showEmailModal={showEmailModal}
            setShowEmailModal={setShowEmailModal} />
          <WhatsAppTicketsModal
            booking={bookings.data[bookingIndex]}
            showWhatsAppTicketsModal={showWhatsAppTicketsModal}
            setShowWhatsAppTicketsModal={setShowWhatsAppTicketsModal} />
          <CancelModal
            booking={bookings.data[bookingIndex]}
            showCancelModal={showCancelModal}
            doHideCancelModal={doHideCancelModal} />
          <RefundModal
            booking={bookings.data[bookingIndex]}
            showRefundModal={showRefundModal}
            doHideRefundModal={doHideRefundModal} />
          <ModificationModal
            booking={bookings.data[bookingIndex]}
            showModificationModal={showModificationModal}
            doHideModificationModal={doHideModificationModal} />
        </>
      }
      <Menu>
        <div className="container">
          <h1>Reservas</h1>
          <br />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filtros</Accordion.Header>
              <Accordion.Body>
                <div className={styles.filters}>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormControl
                          placeholder="Localizador"
                          aria-label="Localizador"
                          name="localizador"
                          aria-describedby="basic-addon1"
                          value={localizador}
                          onChange={pushSearchParam}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          name="localizador"
                          value=""
                          onClick={pushSearchParam}
                        >
                          x
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormControl
                          placeholder="Nombre"
                          aria-label="Nombre"
                          name="name"
                          aria-describedby="basic-addon1"
                          value={name}
                          onChange={pushSearchParam}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          name="name"
                          value=""
                          onClick={pushSearchParam}
                        >
                          x
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormControl
                          placeholder="e-mail"
                          aria-label="email"
                          name="mail"
                          aria-describedby="basic-addon1"
                          value={mail}
                          onChange={pushSearchParam}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          name="mail"
                          value=""
                          onClick={pushSearchParam}
                        >
                          x
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="compra-form-control">
                          Fecha de compra:
                        </FormLabel>

                        <FormControl
                          type="date"
                          name="createdAt"
                          id="compra-form-control"
                          placeholder="yyyy-mm-dd"
                          aria-label="compra"
                          aria-describedby="basic-addon1"
                          value={createdAt}
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="fecha-form-control">
                          Fecha de salida:
                        </FormLabel>
                        <FormControl
                          type="date"
                          name="fecha"
                          placeholder="yyyy-mm-dd"
                          id="fecha-form-control"
                          aria-label="fecha"
                          aria-describedby="basic-addon5"
                          value={fecha}
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="fecha-r-form-control">
                          Fecha de regreso:
                        </FormLabel>
                        <FormControl
                          type="date"
                          name="fechaRegreso"
                          placeholder="yyyy-mm-dd"
                          id="fecha-r-form-control"
                          aria-label="fecha_regreso"
                          aria-describedby="basic-addon5"
                          value={fechaRegreso}
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="status-select">
                          Status:
                        </FormLabel>
                        <Form.Select
                          id="status-select"
                          name="status"
                          value={status}
                          onChange={pushSearchParam}
                          aria-label="Default select example"
                        >
                          <option value="">Todos</option>
                          <option value="confirmed">Confirmed</option>
                          <option value="pending">Pending</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="refunded">refunded</option>
                          <option value="failed">failed</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>

                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="trayecto-select">
                          Ruta:
                        </FormLabel>
                        <Form.Select
                          name="trayecto"
                          id="trayecto-select"
                          onChange={pushSearchParam}
                          aria-label="Default select example"
                        >
                          <option value="">Todos</option>
                          <option value="FOR">Formentera</option>
                          <option value="IBZ">Ibiza</option>
                          <option value="STA">Santa Eulalia</option>
                          <option value="ESC">Es Canar</option>
                          <option value="CLL">Cala Llonga</option>
                          <option value="CPD">Cala Pada</option>
                          <option value="FIG">Es Figueral</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="vendedor-select">
                          Vendedor:
                        </FormLabel>
                        <Form.Select
                          id="vendedor-select"
                          name="vendedor"
                          onChange={pushSearchParam}
                          aria-label="Vendedor select"
                          disabled={userDetails.user.role.type === "hotel"}
                          value={vendedor}
                        >
                          {userDetails.user.role.type === "hotel" ?
                            <>
                              <option value={userDetails.user.id}>{userDetails.user.username}</option>
                            </>
                            :
                            <>
                              <option value="">Todos</option>
                              {users.isSuccess && users.data.map(user => {
                                return <option value={user.id} key={user.id} selected={vendedor === user.id}>{user.username}</option> // eslint-disable-line
                              })}
                            </>}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12} className="p-">
                      {excelStatus === "success" &&
                        <ExcelFile
                          /**</Col>element={
                            <Button
                              variant="primary"
                              className="m-3"
                              disabled={excelStatus !== "success"}
                            >
                              Descargar Excel
                            </Button>
                          }*/
                          hideElement={true}
                        >
                          <ExcelSheet data={bookingsToExport} name="Bookings">
                            <ExcelColumn label="Status" value="status" />
                            <ExcelColumn label="Localizador" value="localizador" />
                            <ExcelColumn label="País" value="country" />
                            <ExcelColumn label="Email" value="email" />
                            <ExcelColumn label="Nombre" value="firstName" />
                            <ExcelColumn label="Apellidos" value="lastName" />
                            <ExcelColumn label="Telf" value="phone_number" />
                            <ExcelColumn label="Idioma" value="lang" />
                            <ExcelColumn
                              label="Fecha de emisión"
                              value={(booking) =>
                                format(new Date(booking.createdAt), "dd-MM-yyyy")
                              }
                            />
                            <ExcelColumn
                              label="Hora de emisión"
                              value={(booking) =>
                                format(new Date(booking.createdAt), "HH:mm")
                              }
                            />
                            <ExcelColumn
                              label="Trayecto"
                              value={(booking) =>
                                booking.ida ? `${booking.ida.details.origin.code} - ${booking.ida.details.destination.code} @ ${booking.ida.details.departure} ` : ""}
                            />
   
                            <ExcelColumn
                              label="Ida y vuelta"
                              value={(booking) => (booking.is_bono ? "BONO" : booking.idayvuelta === "si" ? "Solo ida" : "Ida y vuelta")}
                            />
                            <ExcelColumn label="Fecha de ida" value="fecha_ida" />
                            <ExcelColumn label="Fecha de regreso" value="fecha_vuelta" />
                            <ExcelColumn label="Residente" value={(booking) => booking.residente ? "Residente" : "-"} />
                            <ExcelColumn label="Adultos" value="adults" />
                            <ExcelColumn label="Niños" value="children" />
                            <ExcelColumn label="Bebés" value="babies" />
                            <ExcelColumn
                              label="Forma de pago"
                              value={(booking) => (booking.facturas?.[0]?.formadepago?.formadepago ? booking.facturas?.[0]?.formadepago?.formadepago : "-")} />
                            <ExcelColumn label="Importe" value="total" />
                            <ExcelColumn label="Descuento" value="discount" />
                            <ExcelColumn label="REZDY Reseller" value={booking =>  booking.reservation?.resellerName || "-"} />
                            <ExcelColumn label="REZDY Order Number" value={booking =>  booking.reservation?.orderNumber || "-"} />
                            <ExcelColumn label="REZDY Reseller Reference" value={booking =>  booking.reservation?.resellerReference || "-"} />
                            <ExcelColumn label="Factura Completa" value="facturacompleta" />
                            <ExcelColumn
                              label="Facturas"
                              value={(booking) => (booking.facturas.length ? booking.facturas.map(factura => `${factura.serie}${factura.numero}=${factura.importe}`).join(", ") : "-")}
                            />
                            <ExcelColumn
                              label="Checkin de Ida"
                              value={(booking) => (booking.checkinida ? `${booking.checkinida.user.username} a las ${booking.checkinida.time}` : "-")}
                            />
                            <ExcelColumn
                              label="Checkin de Regreso"
                              value={(booking) => (booking.checkinvuelta ? `${booking.checkinvuelta.user.username} a las ${booking.checkinvuelta.time}` : "-")}
                            />
                            <ExcelColumn
                              label="Checkin de Bono"
                              value={(booking) => (booking.bonocheckin.length ? booking.bonocheckin.map(check => `${check.user.username} a las ${check.time}`).join(", ") : "-")}
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      }
                      <Button
                        variant="primary"
                        className="m-3"
                        disabled={excelStatus === "loading" || userDetails.user.username !== "toni"}
                        onClick={() => fetchBookingsToExport()}>
                        {excelStatus === "loading" ? <><Spinner animation="border" size="sm" /> Exportando...</> : "Exportar a Excel"}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          {bookings.isLoading ? (
            <LoadingSpinner />
          ) : (
            <BookingsTable bookings={bookings.data} sorting={sorting} sortWay={sortWay} sortBy={sortBy} currentPage={currentPage} setCurrentPage={setCurrentPage} doShowModal={doShowModal} PER_PAGE={PER_PAGE} />
          )}
        </div>
      </Menu>
    </>
  );
}

export default Bookings;
